import React from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';

const PaymentPopupButton = ({
  // payMethod = 'VACNT',
  payMethod = 'CARD',
  goodsNm,
  goodsAmt,
  ordNm,
  ordTel,
  setIsPaymentModalOpen,
  setPaymentInfo,
  setIsConfirmOpened,
}) => {
  let popupWindow;

  const initiatePayment = async (e) => {
    e.preventDefault();
    if (!goodsNm || !goodsAmt || !ordNm || !ordTel) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    try {
      const paymentInfo = {
        payMethod,
        goodsNm,
        goodsAmt,
        ordNm,
        ordTel,
      };

      const prepareResponse = await axios.post('/api/payment/prepare', paymentInfo);
      const pgData = prepareResponse.data;

      // Open popup for payment
      const popupWidth = 500;
      const popupHeight = 600;
      const left = window.screen.width / 2 - popupWidth / 2;
      const top = window.screen.height / 2 - popupHeight / 2;

      popupWindow = window.open(
        '',
        'PaymentPopup',
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
      );

      const PG_URL = process.env.REACT_APP_PG_URL;

      if (popupWindow && prepareResponse && prepareResponse.data) {
        const form = popupWindow.document.createElement('form');
        form.method = 'post';
        form.action = `${PG_URL}/v2/auth`;

        Object.entries(pgData).forEach(([key, value]) => {
          const input = popupWindow.document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = value;
          form.appendChild(input);
        });

        popupWindow.document.body.appendChild(form);

        window.addEventListener('message', handlePaymentResult, false);
        form.submit();
      }
    } catch (error) {
      console.error('결제 초기화 오류', error);
      alert('결제 준비 중 오류가 발생했습니다.');
    }
  };

  const handlePaymentResult = (event) => {
    if (!!event.data.data) {
      if (event.data.resultCode === '0000') {
        axios
          .post("/api/payment/approve", event.data.data)
          .then((response) => {
            // 결제 성공 모달 표시
            setPaymentInfo({
              status: "success",
              ordNo: response.data.ordNo,
              amount: response.data.amt,
              payMethod: response.data.payMethod,
              bankName: response.data.fnNm,
              vacntNo: response.data.vacntNo,
            });
            setIsPaymentModalOpen(true);
          })
          .catch((error) => {
            // 백엔드 승인 실패 처리
            setPaymentInfo({
              status: "failed",
              message: error.response?.data?.message || "결제 승인에 실패했습니다.",
            });
            setIsPaymentModalOpen(true);
          });

        // // 결제 성공 모달 표시
        // setPaymentInfo({
        //   status: 'success',
        //   ordNo: event.data.data.ordNo,
        //   amount: event.data.data.amt,
        //   payMethod: event.data.data.payMethod,
        //   bankName: event.data.data.fnNm,
        //   vacntNo: event.data.data.vacntNo,
        // });
        if (popupWindow) {
          popupWindow.close();
        }
        setIsConfirmOpened(false);
        // setIsPaymentModalOpen(true);
        return;
      } else {
        // 결제 실패 모달 표시
        setPaymentInfo({
          status: 'failed',
          message: event.data.resultMsg,
        });
        if (popupWindow) {
          popupWindow.close();
        }
        setIsConfirmOpened(false);
        setIsPaymentModalOpen(true);
        return;
      }
    }
  };

  return (
    <>
      <Button icon={'check'} color={'yellow'} content={'예약 및 결제하기'} onClick={(e) => initiatePayment(e)} />
    </>
  );
};

export default PaymentPopupButton;
